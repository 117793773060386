import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";
import store from "./store/store";
import axios from "axios";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "/",
      redirect: "dashboard",
      component: () => import("@/layouts/full-layout/Layout"),
      meta: {
        requiresLogin: true,
      },
      children: [
        {
          name: "Dashboard",
          path: "/",
          component: () => import("@/views/users/Dashboard"),
        },
        {
          name: "Buildings",
          path: "empreendimentos",
          component: () => import("@/views/users/Buildings"),
        },
        {
          name: "Interested",
          path: "interessados",
          component: () => import("@/views/users/Interested"),
        },
        {
          name: "BuildingsID",
          path: "empreendimentos/:id",
          component: () => import("@/views/users/Building"),
        },
        {
          name: "Campaigns",
          path: "campanhas",
          component: () => import("@/views/users/Campaigns"),
        },
        {
          name: "MyPlan",
          path: "plano",
          component: () => import("@/views/users/MyPlan"),
        },
        {
          name: "MyPayments",
          path: "pagamentos",
          component: () => import("@/views/users/MyPayments"),
        },
      ],
    },

    {
      path: "/admin",
      redirect: { name: "AdminDashboard" },
      component: () => import("@/layouts/full-layout/Layout"),
      meta: {
        requiresLogin: true,
        requiresAdmin: true,
      },

      children: [
        {
          name: "AdminDashboard",
          path: "dashboard",
          component: () => import("@/views/admin/AdminDashboard"),
        },
        {
          name: "AdminBuildings",
          path: "empreendimentos",
          component: () => import("@/views/admin/buildings/Buildings"),
        },
        {
          name: "AdminCompanies",
          path: "empresas",
          component: () => import("@/views/admin/companies/Companies"),
        },
      ],
    },

    {
      path: "/vendas",
      redirect: { name: "SellerAdmin" },
      component: () => import("@/layouts/full-layout/Layout"),
      meta: {
        requiresLogin: true,
        requiresSales: true,
      },

      children: [
        {
          name: "SellerAdmin",
          path: "dashboard",
          component: () => import("@/views/seller/Dashboard"),
        },
        {
          name: "SellerUsers",
          path: "usuarios",
          component: () => import("@/views/seller/Users"),
        },
        {
          name: "SellerCompanies",
          path: "empresas",
          component: () => import("@/views/seller/Companies"),
        },
        {
          name: "SellerInvoices",
          path: "invoices",
          component: () => import("@/views/seller/Invoices"),
        },
      ],
    },

    {
      path: "/auth",
      redirect: { name: "Signin" },
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "Signin",
          path: "signin",
          component: () => import("@/views/authentication/Signin"),
        },
        {
          name: "Signup",
          path: "signup",
          component: () => import("@/views/authentication/Signup"),
        },
      ],
    },
    {
      path: "*",
      name: "Error",
      component: () => import("@/views/errors/Error"),
    },
  ],
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.beforeEach(async (to, from, next) => {
  let user = store.getters.GET_USER;
  if (user) {
    await axios
      .post(`${process.env.VUE_APP_BASE_URL}/validate_token`, {
        token: user?.token,
      })
      .then(() => store.dispatch("SET_USER", user))
      .catch(() => store.dispatch("LOGOUT"));
  }
  to.matched.some((record) => {
    if (record.meta.requiresLogin) {
      user ? next() : next({ path: "/auth" });
    }
    if (record.meta.requiresAdmin) {
      user?.role == "admin" ? next() : next({ path: "/" });
    }
    if (record.meta.requiresSales) {
      user?.role == "seller" ? next() : next({ path: "/" });
    }
    next();
  });
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
