<template>
  <v-app
    id="materialpro"
    :class="`${
      !$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'
    }`"
  >
    <router-view />
  </v-app>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "App",

  components: {},
  methods: {
    ...mapActions({ setUser: "SET_USER", logout: "LOGOUT" }),
    async renewClient() {
      let user = await this.getUser;
      await axios
        .post(`${process.env.VUE_APP_BASE_URL}/validate_token`, {
          token: user?.token,
        })
        .then(() => this.setUser(user))
        .catch(() => this.logout());
    },
  },
  computed: {
    ...mapGetters({ getUser: "GET_USER" }),
  },
  beforeMount() {},
  beforeUpdate() {
    // this.renewClient();
  },
};
</script>
