<template>
  <v-hover v-slot="{ hover }">
    <div>
      <div class="items">
        <v-btn
          :class="{ 'show-btns': hover }"
          :color="transparent"
          icon
          @click.stop="clickSelected"
          v-if="selected"
        >
          <v-icon
            :class="{ 'show-btns': hover || selected }"
            :color="transparent"
          >
            mdi-checkbox-outline
          </v-icon>
        </v-btn>
        <v-btn
          :class="{ 'show-btns': hover }"
          :color="transparent"
          icon
          @click.stop="clickSelected"
          v-else
        >
          <v-icon :class="{ 'show-btns': hover }" :color="transparent">
            mdi-checkbox-blank-outline
          </v-icon>
        </v-btn>
        <v-btn
          :class="{ 'show-btns': hover }"
          :color="transparent"
          icon
          @click.stop="onClickChange"
          v-if="isAdmin"
        >
          <v-icon :class="{ 'show-btns': hover }" :color="transparent">
            mdi-pencil
          </v-icon>
        </v-btn>
      </div>
      <v-card
        class="mx-3 my-2"
        max-width="280"
        height="500"
        @click.stop="onClickRedirect"
        :elevation="hover ? 12 : 2"
        :class="{ 'on-hover': hover || selected }"
      >
        <v-carousel
          v-if="building.images?.length > 0"
          cycle
          hide-delimiters
          :show-arrows="false"
          height="220"
        >
          <v-carousel-item
            v-for="(image, i) in building.images"
            :key="i"
            :src="image.url"
          ></v-carousel-item>
        </v-carousel>
        <v-img
          v-else
          height="220"
          lazy-src="https://liftlearning.com/wp-content/uploads/2020/09/default-image.png"
        ></v-img>

        <v-card-title class="title">{{ building.name }}</v-card-title>

        <v-card-text>
          <div class="my-4 text-subtitle-1">
            {{ building.construction_companies }}
          </div>
          <div class="text-subtitle-2">{{ building.city }}</div>
          <div class="text-subtitle-2">Torres: {{ building.towers }}</div>
          <div class="text-subtitle-2">Unidades: {{ building.units }}</div>

          <div class="text-truncate">
            {{ building.description }}
          </div>
        </v-card-text>
      </v-card>
    </div>
  </v-hover>
</template>

<script>
export default {
  name: "BaseCardBuildings",
  props: {
    building: {
      type: Object,
      require,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    onSelected: {
      type: Function,
      default: () => false,
    },
    onClickChange: {
      type: Function,
      default: () => false,
    },
    onClickRedirect: {
      type: Function,
      default: () => false,
    },
  },
  data: () => ({
    transparent: "rgba(255, 255, 255, 0)",
  }),
  methods: {
    clickSelected() {
      let value = !this.selected;
      let item = {
        id: this.building?.id,
        name: this.building?.name,
      };
      this.onSelected({ value, item });
    },
  },
};
</script>

<style scoped>
.v-card {
  transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.6;
}

.show-btns {
  color: rgb(250, 222, 8) !important;
}
.items {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  margin: 8px 15px;
}
.title {
  word-break: normal;
}
</style>