import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user:
      JSON.parse(localStorage.getItem(process.env.VUE_APP_USER_TOKEN)) || null,
    Sidebar_drawer: null,
    Customizer_drawer: false,
    SidebarColor: "#B22222", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
    SidebarBg: "",
    navbarColor: "#8B0000",
    setHorizontalLayout: false, // Horizontal layout
    selectedBuildings: [],
    interested: 0,
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_NAVBAR_COLOR(state, payload) {
      state.navbarColor = payload;
    },
    SET_LAYOUT(state, payload) {
      state.setHorizontalLayout = payload;
    },
  },
  actions: {
    LOGOUT({ commit }) {
      localStorage.removeItem(process.env.VUE_APP_USER_TOKEN);
      delete axios.defaults.headers.common["Authorization"];
      commit("SET_USER", {});
      router.go("/auth");
    },
    SET_USER({ commit, dispatch }, payload) {
      if (!payload) dispatch("LOGOUT");
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${payload.token}`;
      commit("SET_USER", payload);
    },
  },
  getters: {
    GET_USER: (state) => state.user,
    GET_ROLE: (state) => state.user?.role,
    GET_COMPANY: (state) => state.user?.company,
    GET_PLAN: (state) => state.user?.plan,
  },
});
